import { isEmpty } from "lodash"
import { useParams } from "react-router-dom"

import { getLastAppliedSegments, getLastVisitedTypeId } from "./lastVisit"
import { useRawSegmentsParam } from "./useSegmentsParam"
import { useRawTypeIdParam } from "./useTypeIdParam"

interface Props {
  children: JSX.Element
}

export function NavigateToLastVisitedArgumentType({ children }: Props) {
  const params = useParams()
  const playbookId = params.playbookId!

  const [typeIdParam, , NavigateToTypeId] = useRawTypeIdParam()
  const typeIdFromLS = getLastVisitedTypeId(playbookId)

  if (typeIdFromLS && !typeIdParam) {
    return <NavigateToTypeId value={typeIdFromLS} replace />
  }

  return children
}

export function NavigateToLastAppliedSegments({ children }: Props) {
  const params = useParams()
  const playbookId = params.playbookId!

  const [segmentsParam, , NavigateToSegments] = useRawSegmentsParam()
  const segmentsFromLS = getLastAppliedSegments(playbookId)

  if (!isEmpty(segmentsFromLS) && !segmentsParam) {
    return <NavigateToSegments value={segmentsFromLS} replace />
  }

  return children
}
