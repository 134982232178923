import { requestToCloseExtensionPanel } from "chrome_extension/messaging"
import {
  IS_CHROME_EXTENSION,
  getClosePanelUponMeetingEndSetting,
} from "chrome_extension/utils"
import { createContext, useContext, useState } from "react"
import { useIntl } from "react-intl"

import { IUpcomingCall } from "api/types/CRMCalls"
import { ICRMCompany } from "api/types/CRMCompanies"
import { ICRMContact } from "api/types/CRMContacts"
import { ICRMDeal } from "api/types/CRMDeals"
import { ICRMMeetingType } from "api/types/CRMMeetingTypes"
import { CALL_SYNCED_ENTITY, ISyncedEntityType } from "api/types/integrations"
import { IMeeting, IUpcomingMeeting } from "api/types/meetings"
import { IPlaybook } from "api/types/playbooks"

import { buildNodeFromText, resetNodes } from "ds/RichTextNew/helpers"
import { MyEditor, MyValue } from "ds/RichTextNew/types"
import { AlertSnackbar } from "ds/Snackbar"

import {
  useCurrentMeetingDiscardMutation,
  useCurrentMeetingEndMutation,
  useCurrentMeetingLinkToExternalMutation,
  useCurrentMeetingUpdateCRMDealMutation,
  useCurrentMeetingUpdateCRMInformationMutation,
  useUpdateMeetingCompanyMutation,
  useUpdateMeetingContactsMutation,
} from "components/App/CRM/Meetings/meetingQueries"
import { useCurrentMeetingLinkToExternalCallMutation } from "components/App/CRM/calls/CRMCallQueries"

import { filterNonArchived } from "services/archivable"
import {
  convertMeetingNotesToClipboardText,
  defineCRMCall,
  defineCRMMeeting,
  saveMeetingSyncedEntityTypeInLS,
  selectPickedSegmentFromMeeting,
} from "services/meetings"

import useConfirm from "utils/hooks/useConfirm"

import { usePlaybook } from "../PlaybookProvider"
import ViewNotes from "./MeetingFab/EndMeetingModal/ViewNotes"

interface ProvidedValue {
  ongoingMeeting: IMeeting | null
  isSubmitting: boolean
  CRMMeeting: IUpcomingMeeting | null
  CRMCall: IUpcomingCall | null
  meetingType: ICRMMeetingType | null
  company: ICRMCompany | null
  contacts: ICRMContact[] | []
  deal: ICRMDeal | null
  syncedEntityType: ISyncedEntityType
  generalNote: MyValue
  setGeneralNote: (generalNote: MyValue) => void
  rawGeneralNote: string
  setRawGeneralNote: (rawGeneralNote: string) => void
  handleMeetingTypeChange: (
    meetingType: ICRMMeetingType | null,
    editorRef: React.RefObject<MyEditor>
  ) => void
  handleSyncedEntityTypeChange: (newSyncedEntityType: ISyncedEntityType) => void
  handleCompanyChange: (company: ICRMCompany | null) => void
  handleContactsChange: (newContacts: ICRMContact[]) => void
  handleDealChange: (deal: ICRMDeal | null) => void
  handleCRMCallChange: (
    _event: React.SyntheticEvent<Element, Event>,
    newCRMMeeting: IUpcomingCall | null
  ) => Promise<void>
  handleCRMMeetingChange: (
    _event: React.SyntheticEvent<Element, Event>,
    newCRMMeeting: IUpcomingMeeting | null
  ) => Promise<void>
  validateAndEndMeeting: (onEnd: () => void) => void
  isMeetingEnding: boolean
  discardMeeting: (onConfirm: () => void) => void
  copyNotesToClipboard: () => Promise<void>
}

const MeetingContext = createContext<ProvidedValue | null>(null)

export function useMeeting() {
  const providedValue = useContext(MeetingContext)
  if (providedValue) return providedValue
  throw new Error(
    "useMeeting was called without being wrapped in a MeetingProvider"
  )
}

interface Props {
  ongoingMeeting: IMeeting | null
  playbook: IPlaybook
  children: React.ReactNode
}

export default function MeetingProvider({
  ongoingMeeting,
  playbook,
  children,
}: Props) {
  const intl = useIntl()
  const confirm = useConfirm()
  const { pickSegments, restorePreMeetingSegments } = usePlaybook()
  const [meetingJustEndedSnack, setMeetingJustEndedSnack] =
    useState<IMeeting | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const initialValue = ongoingMeeting?.generalNote || buildNodeFromText("")
  const [generalNote, setGeneralNote] = useState(initialValue)
  const [rawGeneralNote, setRawGeneralNote] = useState(
    ongoingMeeting?.rawGeneralNote || ""
  )

  const updateCRMInformationMutation =
    useCurrentMeetingUpdateCRMInformationMutation(playbook.id)
  const updateCRMDealMutation = useCurrentMeetingUpdateCRMDealMutation(
    playbook.id
  )
  const updateContactsMutation = useUpdateMeetingContactsMutation(playbook.id)
  const updateCompanyMutation = useUpdateMeetingCompanyMutation(playbook.id)
  const currentMeetingLinkMeetingToExternalCallMutation =
    useCurrentMeetingLinkToExternalCallMutation(playbook.id)
  const currentMeetingLinkToExternalMutation =
    useCurrentMeetingLinkToExternalMutation(playbook.id)
  const endMutation = useCurrentMeetingEndMutation(playbook.id)
  const discardMutation = useCurrentMeetingDiscardMutation(playbook.id)

  function handleMeetingTypeChange(
    meetingType: ICRMMeetingType | null,
    editorRef: React.RefObject<MyEditor>
  ) {
    if (!ongoingMeeting) return

    updateCRMInformationMutation.mutate(
      {
        id: ongoingMeeting.id,
        meeting: { CRMMeetingTypeId: meetingType ? meetingType.id : null },
      },
      {
        onSuccess: () => {
          if (
            !meetingType?.rawGeneralNoteTemplate ||
            !meetingType.generalNoteTemplate
          )
            return
          const applyMeetingTypeTemplateMessage = intl.formatMessage({
            id: "meetingType.generalNoteTemplate.apply",
            defaultMessage:
              "Do you want to override the current notes with the template of the new meeting type?",
          })

          if (
            !ongoingMeeting.rawGeneralNote ||
            window.confirm(applyMeetingTypeTemplateMessage)
          ) {
            setGeneralNote(meetingType.generalNoteTemplate)
            setRawGeneralNote(meetingType.rawGeneralNoteTemplate)
            if (editorRef.current) {
              resetNodes(editorRef.current, {
                nodes: meetingType.generalNoteTemplate,
              })
              editorRef.current.onChange()
            }
          }
        },
      }
    )
  }

  function handleSyncedEntityTypeChange(
    newSyncedEntityType: ISyncedEntityType
  ) {
    if (!ongoingMeeting) return
    saveMeetingSyncedEntityTypeInLS(newSyncedEntityType)
    updateCRMInformationMutation.mutate({
      id: ongoingMeeting.id,
      meeting: { syncedEntityType: newSyncedEntityType },
    })
  }

  function handleCompanyChange(company: ICRMCompany | null) {
    if (!ongoingMeeting) return

    updateCompanyMutation.mutate(
      {
        meetingId: ongoingMeeting.id,
        companyId: company ? company.id : null,
      },
      {
        onSuccess: (meeting: IMeeting) =>
          pickSegments(
            selectPickedSegmentFromMeeting(
              meeting,
              filterNonArchived(playbook.argumentSegmentations)
            )
          ),
      }
    )
  }

  function handleContactsChange(newContacts: ICRMContact[]) {
    if (!ongoingMeeting) return

    updateContactsMutation.mutate(
      {
        meetingId: ongoingMeeting.id,
        contactIds: newContacts.map((x) => x.id),
      },
      {
        onSuccess: (meeting: IMeeting) =>
          pickSegments(
            selectPickedSegmentFromMeeting(
              meeting,
              filterNonArchived(playbook.argumentSegmentations)
            )
          ),
      }
    )
  }

  function handleDealChange(deal: ICRMDeal | null) {
    if (!ongoingMeeting) return

    updateCRMDealMutation.mutate(
      {
        id: ongoingMeeting.id,
        crmDealId: deal?.id,
      },
      {
        onSuccess: (meeting: IMeeting) =>
          pickSegments(
            selectPickedSegmentFromMeeting(
              meeting,
              filterNonArchived(playbook.argumentSegmentations)
            )
          ),
      }
    )
  }
  async function handleCRMCallChange(
    _event: React.SyntheticEvent<Element, Event>,
    selectedCallOption: IUpcomingCall | null
  ) {
    try {
      if (!ongoingMeeting) return
      setIsSubmitting(true)

      await currentMeetingLinkMeetingToExternalCallMutation.mutateAsync(
        {
          meetingId: ongoingMeeting.id,
          externalCallId: selectedCallOption?.externalId,
        },
        {
          onSuccess: (meeting: IMeeting) =>
            pickSegments(
              selectPickedSegmentFromMeeting(
                meeting,
                filterNonArchived(playbook.argumentSegmentations)
              )
            ),
        }
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  async function handleCRMMeetingChange(
    _event: React.SyntheticEvent<Element, Event>,
    newCRMMeeting: IUpcomingMeeting | null
  ) {
    try {
      if (!ongoingMeeting) return
      setIsSubmitting(true)

      await currentMeetingLinkToExternalMutation.mutateAsync(
        {
          meetingId: ongoingMeeting.id,
          externalMeetingId: newCRMMeeting?.externalId,
        },
        {
          onSuccess: (meeting: IMeeting) =>
            pickSegments(
              selectPickedSegmentFromMeeting(
                meeting,
                filterNonArchived(playbook.argumentSegmentations)
              )
            ),
        }
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  function resetMeetingForm() {
    setGeneralNote(buildNodeFromText(""))
    setRawGeneralNote("")
  }

  function validateAndEndMeeting(onEnd: () => void) {
    if (!ongoingMeeting) return
    endMutation.mutate(ongoingMeeting.id, {
      onSuccess: async () => {
        resetMeetingForm()
        setMeetingJustEndedSnack(ongoingMeeting)
        restorePreMeetingSegments()
        if (IS_CHROME_EXTENSION) {
          const closePanelUponMeetingEndSetting =
            await getClosePanelUponMeetingEndSetting()
          if (closePanelUponMeetingEndSetting) {
            requestToCloseExtensionPanel()
          }
        }
        onEnd()
      },
    })
  }

  function discardMeeting(onConfirm: () => void) {
    if (!ongoingMeeting) return
    if (confirm()) {
      discardMutation.mutate(ongoingMeeting.id)
      onConfirm()
      resetMeetingForm()
      restorePreMeetingSegments()
    }
  }

  async function copyNotesToClipboard() {
    if (!ongoingMeeting) return
    return navigator.clipboard.writeText(
      convertMeetingNotesToClipboardText(ongoingMeeting, intl)
    )
  }

  return (
    <MeetingContext.Provider
      value={{
        ongoingMeeting,
        isSubmitting,
        CRMMeeting: defineCRMMeeting(ongoingMeeting),
        meetingType: ongoingMeeting?.crmMeetingType || null,
        company: ongoingMeeting?.crmCompany || null,
        contacts: ongoingMeeting?.crmContacts || [],
        deal: ongoingMeeting?.crmDeal || null,
        CRMCall: defineCRMCall(ongoingMeeting),
        syncedEntityType:
          ongoingMeeting?.syncedEntityType || CALL_SYNCED_ENTITY,
        generalNote,
        setGeneralNote,
        rawGeneralNote,
        setRawGeneralNote,
        handleMeetingTypeChange,
        handleSyncedEntityTypeChange,
        handleCompanyChange,
        handleContactsChange,
        handleDealChange,
        handleCRMCallChange,
        handleCRMMeetingChange,
        validateAndEndMeeting,
        isMeetingEnding: endMutation.isLoading,
        discardMeeting,
        copyNotesToClipboard,
      }}
    >
      <>
        {children}

        <AlertSnackbar
          severity="success"
          open={!!meetingJustEndedSnack}
          onClose={() => setMeetingJustEndedSnack(null)}
        >
          {!!meetingJustEndedSnack ? (
            <ViewNotes ongoingMeeting={meetingJustEndedSnack} />
          ) : undefined}
        </AlertSnackbar>
      </>
    </MeetingContext.Provider>
  )
}
