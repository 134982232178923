import { MyValue } from "ds/RichTextNew/types"

import { ICRMCompany } from "./CRMCompanies"
import { ICRMContact } from "./CRMContacts"
import { ICRMDeal } from "./CRMDeals"
import { ICRMMeetingType } from "./CRMMeetingTypes"
import { ISyncedEntityType } from "./integrations"
import { IMeetingArgumentNote } from "./meetingArgumentNotes"
import { IUser } from "./users"
import { IVote } from "./votes"

export interface IMeeting {
  id: string
  user: IUser
  playbookId: string
  businessUnitId: string
  title: string | null
  startTime: string
  generalNote: MyValue | null
  rawGeneralNote: string | null
  votes: IVote[]
  meetingArgumentNotes: IMeetingArgumentNote[]
  crmCompany: ICRMCompany | null
  crmContacts: ICRMContact[]
  crmDeal: ICRMDeal | null
  crmMeetingType: ICRMMeetingType | null
  syncedEntityType: ISyncedEntityType
  integrationName: string | null
  workspaceId: string | null
  externalId: string | null
  externalCallId: string | null
  createdAt: string
}

export function isMeetingLinkedToExternalCRM(
  meeting: IMeeting
): meeting is IMeetingLinkedToExternalCRM {
  return (
    !!meeting.externalId && !!meeting.integrationName && !!meeting.workspaceId
  )
}

export function isMeetingLinkedToExternalCRMCall(
  meeting: IMeeting
): meeting is IMeetingLinkedToExternalCRMCall {
  return (
    !!meeting.externalCallId &&
    !!meeting.integrationName &&
    !!meeting.workspaceId
  )
}

export interface IMeetingLinkedToExternalCRM extends IMeeting {
  externalId: string
  integrationName: string
  workspaceId: string
}

export interface IMeetingLinkedToExternalCRMCall extends IMeeting {
  externalCallId: string
  integrationName: string
  workspaceId: string
}

export interface IUpcomingMeeting {
  externalId: string
  integrationName: string
  workspaceId: string
  title: string | null
  startTime: string
  externalContactIds?: string[]
  externalCompanyId?: string
}

export interface IMeetingsFiltersParams {
  crmCompanyId?: string | null
  crmContactId?: string | null
  crmMeetingTypeId?: string | null
  userId?: string | null
}

export interface IMeetingsIndexResponse {
  meetings: IMeeting[]
  paginationCursor: string | null
}

export interface IMeetingCreateParams {
  playbookId: string
  syncedEntityType: string | null
  crmContactExternalId?: string | null
  crmCompanyExternalId?: string | null
  crmDealExternalId?: string | null
}

export type IMeetingCreateResponse = IMeeting

export interface IMeetingUpdateGeneralNoteParams {
  id: string
  value: MyValue | null
  rawValue: string | null
}

export interface IMeetingUpdateCRMInformationParams {
  id: string
  meeting: {
    CRMMeetingTypeId?: string | null
    syncedEntityType?: ISyncedEntityType
  }
}

export interface IMeetingUpdateCRMDealParams {
  id: string
  crmDealId?: string
}
export interface IMeetingUsersSearchParams {
  businessUnitId: string
  name: string
}
